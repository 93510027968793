<template>
    <div>
        <b-card title="隐私政策管理">
            <b-card-text>
                <span>
                    个人信息处理者、企业必须按照规定对个人信息采取必要的安全保护措施。企业应建立和完善内部个人信息管理制度，对个人信息实行分级分类管理，采取相应的加密、去标识化等安全技术措施。
                </span>
            </b-card-text>
        </b-card>
        <b-card>
            <!-- search & new -->
            <b-row>
                <b-col cols="3">
                    <b-input-group class="mb-1" prepend="发文编号">
                        <b-form-input placeholder="发文编号" v-model="query_code" @keypress.enter="doSearch" />
                    </b-input-group>
                </b-col>
                <b-col cols="3">
                    <b-input-group class="mb-1" prepend="发布日期">
                        <b-form-input id="date-input" type="text" v-model="query_date" placeholder="YYYY-MM-DD" autocomplete="off" show-decade-nav />
                        <b-input-group-append>
                            <b-form-datepicker show-decade-nav v-model="query_date" button-only right size="sm" locale="zh-CN" aria-controls="date-input" />
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="3">
                    <b-input-group prepend="关键字">
                        <b-form-input type="text" v-model="query_keyword" placeholder="法规内容、关键字" @keypress.enter="doSearch" />
                    </b-input-group>
                </b-col>
                <b-col cols="3" class="text-left">
                    <b-button variant="primary" @click="doSearch">
                        <feather-icon class="mr-50" icon="SearchIcon" />
                        <span class="align-middle">检索</span>
                    </b-button>
                    <b-button variant="primary" @click="showEditDlg()" class="ml-1">
                        <feather-icon class="mr-50" icon="PlusIcon" />
                        <span class="align-middle">新增</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <!-- list -->
        <b-row v-if="queryData" class="match-height">
            <b-col xl="3" md="2" v-for="(item, idx) in queryData" :key="idx">
                <b-card img-width="10%" no-body class="lab-item" footer-class="lab-footer">
                    <b-card-header>
                        <b-media vertical-align="top">
                            <template #aside>
                                <b-badge v-for="(lawItem, lawIdx) in item.compliance.split(',')" :key="`lay-${lawIdx}`" variant="light-primary" class="mr-1">
                                    <b-icon-shield-lock /> <span>{{ lawItem }}</span>
                                </b-badge>
                            </template>
                        </b-media>
                        <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <h3>{{ item.title }}</h3>
                            <h5>
                                <b-badge variant="light-secondary" class="mr-1" size="sm">{{
                                    item.issueDate
                                    }}</b-badge>
                                {{ item.issueNo }}
                            </h5>
                        </div>
                        <div>{{ item.abstract }}</div>
                    </b-card-body>
                    <b-card-footer class="text-right">
                        <b-button variant="outline-primary" size="sm" @click="preview(item)" class="mr-1">
                            <feather-icon icon="EditIcon" /> 查看</b-button>
                        <b-button variant="outline-danger" size="sm" @click="showEditDlg(item)">
                            <feather-icon icon="EditIcon" /> 编辑</b-button>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <!-- pagination -->
        <b-row>
            <b-col cols="12" class="mt-1">
                <b-pagination v-if="queryData" v-model="listTableCurrentPage" :total-rows="listTableTotalRows" :per-page="listTablePerPage" align="center" size="sm" class="my-0" />
            </b-col>
        </b-row>
        <!-- edit -->
        <b-sidebar id="sidebar-edit" bg-variant="white" width="35%" right backdrop shadow :title="currentItem.title" v-if="currentItem" v-model="showEdit">
            <policy-edit :obj="currentItem" :toSave="toSave" @close="showEdit = false" @save="onSave" />
            <template #footer>
                <div class="p-2 border-top text-right">
                    <b-button variant="primary" class="mr-1" @click="toSave = true">保存</b-button>
                    <b-button variant="secondary" @click="showEdit = false;toSave = false;">取消</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>
<script>
import {
    BMedia,
    BMediaAside,
    BIconShieldLock,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BSidebar,
    VBToggle,
    BFormTextarea,
    BFormFile,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import PolicyEdit from "./PolicyEdit.vue";

// import EnterpriseEdit from "./EnterpriseEdit.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import stringify from "uuid/dist/stringify";
import { init } from "echarts/lib/echarts";
import store from "@/store";

export default {
    name: "Policy",

    components: {
        BMedia,
        BMediaAside,
        BIconShieldLock,
        BImg,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardFooter,
        BCardBody,
        BCardText,
        BFormSelect,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BTable,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInvalidFeedback,
        BAvatar,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormDatepicker,
        BSidebar,
        BFormTextarea,
        BFormFile,
        BCardCode,
        AppCollapse,
        AppCollapseItem,
        PolicyEdit,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    data() {
        return {
            queryData: null,

            // query
            query_code: "",
            query_date: "",
            query_keyword: "",

            // list table
            listTableFields: [
                { key: "issueNo", label: "编号" },
                { key: "title", label: "主题" },
                { key: "compliances", label: "合规要求" },
                { key: "language", label: "语言" },
                { key: "issueDate", label: "发布日期" },
                { key: "abstract", label: "简介" },
                { key: "sourceFile", label: "源文件" },
                { key: "displayFile", label: "PDF文件" },
                { key: "attachments", label: "附件材料" },
                { key: "actions", label: "操作" },
            ],
            listTablePerPage: 5,
            listTablePageOptions: [5, 15, 30],
            listTableTotalRows: 1,
            listTableCurrentPage: 1,

            // edit
            currentItem: null,
            baseInfoVisible: true,
            adminUser: null,
            showEdit: false,

            selected: [],
            complianceOptions: ["GDPR", "CCPA", "PIPL"],

            policyNew: {
                id: 0,
                eid: 0,
                issueNo: "0",
                title: "新建企业隐私政策",
                language: "简体中文",
                abstract: "",
                compliance: "",
                templateFile: "",
                displayFile: null,
                attachment: "",
                history: null,
                issueDate: null,
                status: 1,
            },
            complianceArray: [], // [] <=>","
            attachmentArray: [], // [] <=>","

            toSave: false,
        };
    },

    computed: {},

    beforeCreate() {
        // dispatch action to load eid
        store.dispatch("appOptions/LOAD_EID");
    },

    created() {
        this.policyNew.eid = store.state.appOptions.eid;
        this.doSearch();
    },

    methods: {
        doSearch(bInit) {
            let params = bInit ?
                null :
                {
                    q: this.query_keyword,
                    // issueCode: this.query_compliance,
                    // issueDate: this.query_date,
                };
            this.$http.get("/privacypolicys", { params }).then((res) => {
                // console.log(res.data);
                this.queryData = res.data.data.list;
                this.listTableTotalRows = res.data.data.total;
            });
            let date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            var newdata = year + "-" + month + "-" + strDate;

            this.policyNew.issueDate = newdata;
            console.log(params);
        },
        preview(item) {
            this.$router.push(`/enterprise/policy/${item.id}`);
            // this.viewItem = item;
            // this.showView = true;
        },
        showEditDlg(item) {
            this.currentItem = item ? item : this.policyNew;

            this.complianceArray = this.currentItem.compliance ?
                this.currentItem.compliance.split(",") :
                [];
            this.showEdit = true;
        },
        onSave(newObj) {
            if (newObj.id) {
                this.$http
                    .put("/privacypolicys/" + newObj.id, newObj)
                    .then((res) => {
                        this.doSearch();
                        this.showEdit = false;
                    });
            } else {
                this.$http.post("/privacypolicys", newObj).then((res) => {
                    this.doSearch();
                    this.showEdit = false;
                });
            }
            // this.$http.post("/api/enterprise-policy" + this.currentItem).then((res) => {
            //   this.$swal({
            //     title: "保存成功",
            //     text: "成功",
            //     icon: "success",
            //   });
            // });
        },

        successed(msg) {
            this.$swal({
                title: "成功",
                text: msg,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "确认",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    // this.backToList();
                }
            });
        },

        uploadToServer() {
            //
        },

        //
        deleteItem(data) {
            let name = data.item.name;
            this.$bvModal
                .msgBoxConfirm(`是否确定删除“ ${name}”？`, {
                    title: "删除确认",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Yes",
                    cancelTitle: "No",
                    cancelVariant: "outline-secondary",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    console.log(value);
                    this.$http.delete(`/enterprise/privacypolicys/${data.item.id}`).then((res) => {
                        this.$bvToast.toast(`${name} 删除成功`, {
                            title: `操作成功`,
                            variant: "success",
                            solid: true,
                        });
                        this.doSearch();
                    });
                });
        },

        onEditHidden() {
            this.showEdit = false;
        },
    },
};
</script>
<style lang="scss">
.lib-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px;
}

.lib-footer {
    font-size: 1rem !important;
    text-align: right !important;
}

.match-height>[class*="col"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    cursor: pointer;
}

.match-height>[class*="col"]>.card {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

label.star::before {
    content: "* ";
    color: red;
}

.introduce {
    width: 400px;
    font-size: 14px;
    font-weight: normal;
    word-wrap: break-word;
}

.more {
    position: absolute;
    bottom: -20px;
    right: 0px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    color: rgb(26, 76, 212);
}
</style>